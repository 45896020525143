var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h_scene flex justify-center bg-white"},[_c('div',{staticClass:"md:w-bodySet flex flex-col items-center"},[_c('h4',{staticClass:"mt-16 mb-4 text-28px text-title-color mb-12"},[_vm._v(" "+_vm._s(_vm.$t('lang.scene'))+" ")]),_c('div',{staticClass:"scene_box relative"},[_c('img',{staticClass:"scene_img_bottom",attrs:{"src":require("@/assets/images/home/scene-bottom.png"),"alt":"场景"}}),_c('div',{ref:"box",staticClass:"scene_img_box absolute text-center",style:({ transition: _vm.isRight ? '0s' : '' })},[_c('img',{staticClass:"scene_img",attrs:{"src":require("@/assets/images/home/scene.png"),"alt":"场景"}}),_c('img',{staticClass:"scene_img absolute",staticStyle:{"left":"2027px","top":"0"},attrs:{"src":require("@/assets/images/home/scene.png"),"alt":"场景"}}),_c('img',{staticClass:"scene_img absolute",staticStyle:{"left":"-2027px","top":"0"},attrs:{"src":require("@/assets/images/home/scene.png"),"alt":"场景"}}),_vm._l((_vm.lists),function(item,_index){return _c('div',{key:_index,staticClass:"dot absolute",style:({
            left: !_vm.enStyle
              ? _vm.l_width * _index + 100 + 'px'
              : _vm.l_width * _index + 100 - 36 + 'px',
          })},[_c('p',{staticClass:"text-14px absolute dot1_text text-article-color",class:{ 'opacity-0': _vm.index === _index },style:({
              top: item.direction === 'u' ? '-15px' : '',
              'white-space': _vm.enStyle ? 'nowrap' : 'nowrap',
            })},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{ref:"block",refInFor:true,staticClass:"dot_block flex flex-col items-center justify-start",class:{ 'opacity-0': _vm.index !== _index },style:({
              width: _vm.enStyle ? '245px' : '',
              height: item.height + 'px',
            })},[_c('div',{staticClass:"circle flex items-center justify-center",on:{"click":function($event){return _vm.clickChange(_index)}}},[_c('div',{staticClass:"circle_dot"})]),_c('h4',{staticClass:"text-18px text-white",class:{
                'mb-4': !_vm.enStyle,
                'mt-7': item.height > 240,
                'mb-12': !_vm.enStyle && item.height > 270,
                'mb-5': _vm.enStyle && item.height > 240,
              }},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"text-14px text-white leading-normal pt-2 flex-grow flex"},[_vm._v(" "+_vm._s(item.text)+" ")])])])})],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }