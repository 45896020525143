





























































































import Vue from 'vue'
import lang from '@/mixin/lang'
export default Vue.extend({
  mixins: [lang],
  data() {
    return {
      index: 2,
      l_width: 226,
      timer: 0,
      isRight: false,
      copyNum: 6,
      prev: [],
      next: [],
    }
  },
  computed: {
    // item 的 height 在 240 或 270 方块区域文本标题存在 margin 间距设置
    list() {
      // height 模块高度
      // direction 曲线拐向 d 向下 u 向上
      return [
        {
          id: 1,
          title: this.$t('lang.SC1'),
          text: this.$t('lang.SC1T'),
          height: 185,
          direction: 'd',
        },
        {
          id: 2,
          title: this.$t('lang.SC2'),
          text: this.$t('lang.SC2T'),
          height: 382,
          direction: 'u',
        },
        {
          id: 3,
          title: this.$t('lang.SC3'),
          text: this.$t('lang.SC3T'),
          height: 263,
          direction: 'd',
        },
        {
          id: 4,
          title: this.$t('lang.SC4'),
          text: this.$t('lang.SC4T'),
          height: 310,
          direction: 'u',
        },
        {
          id: 5,
          title: this.$t('lang.SC5'),
          text: this.$t('lang.SC5T'),
          height: 180,
          direction: 'd',
        },
        {
          id: 6,
          title: this.$t('lang.SC6'),
          text: this.$t('lang.SC6T'),
          height: 380,
          direction: 'u',
        },
        {
          id: 7,
          title: this.$t('lang.SC7'),
          text: this.$t('lang.SC7T'),
          height: 195,
          direction: 'd',
        },
        {
          id: 8,
          title: this.$t('lang.SC8'),
          text: this.$t('lang.SC8T'),
          height: 325,
          direction: 'u',
        },
        {
          id: 9,
          title: this.$t('lang.SC9'),
          text: this.$t('lang.SC9T'),
          height: 450,
          direction: 'u',
        },
      ]
    },
    lists() {
      const prev = this.prev as any
      let next = this.next as any
      const list = this.list as any
      next = JSON.parse(JSON.stringify(list.slice(0, this.copyNum)))
      return [...prev, ...list, ...next]
    },
  },
  methods: {
    clickChange(i: number) {
      // 回归点设置
      let b =
        i === this.list.length + this.copyNum - 3 ||
        i === this.list.length + this.copyNum - 4
      this.index = i
      this.moveBlock(this.index, b)
      this.initTimer()
    },
    moveBlock(i: number, b: boolean) {
      if (!this.$refs.box) return

      const el = (this.$refs.box as any & { style: () => any })?.style
      if (!el) return

      // 当第四个点时回归原始translate
      if (b) {
        el.transform = `translate3d(-${this.l_width * (i - 2)}px, 0, 0)`
        setTimeout(() => {
          el.transition = '0s'
          el.transform =
            i === 12
              ? `translateX(-${this.l_width * (i - 2 - this.list.length)}px)`
              : 'translateX(0)'
          this.index = i === 12 ? 3 : 2
          const array = this.$refs.block as any
          for (const item of array) {
            item.style.transition = '0s'
          }
        }, 300)
        setTimeout(() => {
          el.transition = ''
          const array = this.$refs.block as any
          for (const item of array) {
            item.style.transition = ''
          }
        }, 500)
        return
      }
      el.transform =
        i >= 2
          ? `translate3d(-${this.l_width * (i - 2)}px, 0, 0)`
          : 'translate3d(0, 0, 0)'
    },
    initTimer() {
      if (this.timer) clearInterval(this.timer)
      this.timer = window.setInterval(() => {
        this.index++
        this.clickChange(this.index)
        if (this.index === 13) {
          const el = (this.$refs.box as any & { style: () => any })?.style
          setTimeout(() => {
            this.index = 4
            el.transition = '0s'
            el.transform = 'translate3D(0, 0, 0)'
            const array = this.$refs.block as any
            for (const item of array) {
              item.style.transition = '0s'
            }
          }, 300)
          setTimeout(() => {
            el.transition = ''
            const array = this.$refs.block as any
            for (const item of array) {
              item.style.transition = ''
            }
          }, 500)
        }
      }, 4000)
    },
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  created() {
    this.next = JSON.parse(JSON.stringify(this.list.slice(0, this.copyNum)))
  },
  mounted() {
    this.initTimer()
  },
  watch: {
    $route() {
      clearInterval(this.timer)
      this.next = JSON.parse(JSON.stringify(this.list.slice(0, this.copyNum)))
      this.initTimer()
    },
  },
})
