























































































































































































































import Vue from "vue";
import Banner from "@/components/pc/banner.vue";
import Explain from "@/components/pc/explain.vue";
import HomeFeature from "@/components/pc/homeFeature.vue";
import Mechanism from "@/components/pc/mechanism.vue";
import Integral from "@/components/pc/integral.vue";
import Scene from "@/components/pc/scene.vue";
import { Tooltip } from "ant-design-vue";
import ccImg from "@/assets/images/blockChainBrowser/cc-image.png";
export default Vue.extend({
  name: "Home",
  data() {
    return {
      isStyleChange: true,
      useEn: false,
      ccImg,
    };
  },
  components: {
    Banner,
    Explain,
    HomeFeature,
    Mechanism,
    Integral,
    Scene,
    Tooltip,
  },
  created() {
    window.addEventListener("scroll", () => {
      const scrollHeight = window.scrollY;
      if (scrollHeight > 4140) {
        this.isStyleChange = false;
      } else {
        this.isStyleChange = true;
      }
    });
  },
  watch: {
    $route(n) {
      this.useEn = n.query?.lang === "en";
    },
  },
});
