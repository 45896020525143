
































import Vue from 'vue'
import explainText from './explainText.vue'
import lang from '@/mixin/lang'
export default Vue.extend({
  components: { explainText },
	data() {
		return {
			index: 1,
			timer: 0
		}
	},
	mixins: [lang],
	methods: {
		handleChange(v: number) {
			this.index = v
			if (this.timer) clearInterval(this.timer)
			this.timer = window.setInterval(() => {
				this.index < 4 ? this.index++ : this.index = 1
			}, 4000)
		}
	},
	created() {
		this.timer = window.setInterval(() => {
			this.index < 4 ? this.index++ : this.index = 1
		}, 4000)
	},
	beforeDestroy() {
		clearInterval(this.timer)
	}
})
