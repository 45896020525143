






















































import Vue from "vue";
import lang from "@/mixin/lang";
export default Vue.extend({
  mixins: [lang],
});
